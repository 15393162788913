<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Bulk Unsubscribe"
    >
      <template #subheader>
        Unsubscribe multiple contacts quickly and efficiently
      </template>
    </PageHeader>

    <div class="form-container">
      <validation-observer ref="textInput">
        <v-row class="py-0">
          <v-col cols="12" class="py-0">
            <CustomTextInput
              header="Unsubscribe Reason"
              :value="unsubscribe.reason"
              :required="true"
              :maxlength="150"
              @input="(val) => unsubscribe.reason = val"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <CustomTextareaV2
              header="Email Addresses"
              placeholder="Enter email addresses, one per line"
              :value="unsubscribe.emails"
              :required="true"
              @input="(val) => unsubscribe.emails = val"
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-col>
      </validation-observer>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import dayjs from "dayjs";
import CustomTextareaV2 from "@/sharedComponents/CustomTextareaV2";

export default {
  name: "BulkUnsubscribeView",
  metaInfo: {
    title: 'Bulk Unsubscribe'
  },
  components: {
    CustomTextareaV2,
    CustomTextInput,
    PageHeader
  },
  data: () => ({
    unsubscribe: {
      reason: "",
      emails: "",
    },
  }),
  methods: {
    async validate() {
      return await this.$refs.textInput.validate();
    },
    async submit() {
      if (!(await this.validate())) {
        this.$notifier.error("Please check the form again.");
        return;
      }

      const payload = {
        optOutReason: this.unsubscribe.reason,
        optOutDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        emails: this.unsubscribe.emails.split(/\n/).map(email => email.trim()),
      };

      try {
        await this.$rest.contact.bulk_unsubscribe(payload);
        this.$notifier.info("The request has been received");

        // clear form
        this.$refs.textInput.reset();
        this.unsubscribe.reason = "";
        this.unsubscribe.emails = "";
      } catch (e) {
        this.$notifier.error("Unexpected error. Please try again or contact us.");
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  margin-top: 40px;
}
</style>